<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="520px" @close="handleClose">
    <el-form label-width="120px" :model="state.form" ref="formRef" :rules="state.rules">
      <template v-if="state.type != 'edit'">
        <el-form-item label="添加数量" prop="count">
          <el-input-number v-model.number="state.form.count" :min="1" placeholder="数量" :disabled="state.type == 'edit'" />
        </el-form-item>
        <el-form-item label="优惠价格" prop="discountPrice">
          <div class="d-flex flex-column">
            <el-input :disabled="state.type == 'edit'" v-model="state.form.discountPrice" oninput="value=value.replace(/^\.+|[^\d.]/g,'')" placeholder="请输入优惠价格" />
            <div style="font-size:12px;">
              <span style="color: red">*</span>
              此价格为用户使用优惠码后实付的价格
            </div>
          </div>
        </el-form-item>
      </template>

      <template v-else>
        <el-form-item label="使用次数总量">
          <el-input-number v-model.number="state.form.allNum" :min="0" placeholder="数量" :disabled="true" />
          <div style="font-size:12px;">
            优惠码总共可以被使用的数量
          </div>
        </el-form-item>
        <el-form-item label="已使用次数">
          <el-input-number v-model.number="state.form.useNum" :min="0" placeholder="数量" :disabled="true" />
          <div style="font-size:12px;">
            优惠码已经被使用的数量
          </div>
        </el-form-item>
      </template>

      <el-form-item :label="state.type != 'edit' ? '使用次数' : '剩余使用次数'" prop="defineUseNum">
        <el-input-number v-model.number="state.form.defineUseNum" :min="0" placeholder="数量" />
        <div style="font-size:12px;">
          <span style="color: red">*</span>
          此使用次数为根据添加数量所生成的每个优惠码的可使用次数
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click.stop="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSave">{{state.type != 'edit' ? '确认创建' : '保存配置'}}</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref, getCurrentInstance } from 'vue'
import {
  reqAddDiscountCode,
  updateDiscountCodeUseNum,
} from '@/api/exhibitionManage'
import { debounce } from '@/utils/debounce'
import { useComponent } from './index'
// 引入组件
const { editConfigDrawer, editConfigDrawer1 } = useComponent()
const props = defineProps({
  title: {
    type: String,
    default: '添加优惠劵',
  },
})
const { proxy } = getCurrentInstance()
const dialogFormVisible = ref(false)
const state = reactive({
  form: {
    count: 1,
    discountPrice: '',
    ticketCode: '',
    defineUseNum: 1,
    id: 1,
  },
  type: 'add',
  rules: {
    count: [
      {
        required: true,
        trigger: 'blur',
        message: '请输入数量',
      },
    ],
    discountPrice: [
      {
        required: true,
        trigger: 'blur',
        message: '请输入优惠价格',
      },
    ],
    defineUseNum: [
      {
        required: true,
        trigger: 'blur',
        message: '请输入使用次数',
      },
    ],
  },
})
const formRef = ref(null)
const emit = defineEmits(['noticeRefresh'])
const handleResetForm = () => {
  state.form = {
    count: '',
    discountPrice: '',
  }
}
// 关闭
const handleClose = () => {
  dialogFormVisible.value = false
}
// 打开
const handleOpen = (row = {}) => {
  console.log(row)
  // 重置表单
  handleResetForm()
  state.form.ticketCode = row.ticketCode || ''
  state.type = row.type
  state.form.defineUseNum = row.count || 1
  state.form.discountPrice = row.discountPrice
  state.form.useNum = row.useNum
  state.form.allNum = row.defineUseNum
  state.form.id = row.id
  dialogFormVisible.value = true
}
// 保存
const handleSave = debounce(() => {
  formRef.value.validate(async (valid) => {
    if (valid) {
      if (state.type == 'edit') {
        let resp = await updateDiscountCodeUseNum(
          state.form.id,
          state.form.defineUseNum
        )
      } else {
        let resp = await reqAddDiscountCode(state.form)
      }
      proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
      emit('noticeRefresh')
      handleClose()
    }
  })
})
defineExpose({
  handleClose,
  handleOpen,
})
</script>
